import React, { useEffect, useContext } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TrackingContext } from "../context/TrackingContext"
import { FlowContext } from "../context/FlowContext"
import { QuestionsDoublePanel } from "../components/QuestionsDoublePanel"
import Loading from "../components/ResultsLayout/Loading"


const QuestionsPage = () => {
  const { trackEvent } = useContext(TrackingContext)
  const { flowFinished } = useContext(FlowContext)

  console.log('render question template')
  console.log("Finished", flowFinished)

  useEffect(() => {
    trackEvent("start", "start")
  }, [])

  return (
    <Layout questions>
      <SEO title="Questions" />

      {flowFinished && <Loading /> }
      {!flowFinished && <QuestionsDoublePanel /> }
    </Layout>
  )
}

export default QuestionsPage
