import React from "react"
import {} from "./style"
import { ReactComponent as Airplane } from "../../images/airplane.svg"
import { LoadingWrapper, Panel, Plane  } from "./style"

const Loading = () => {
  return (
    <LoadingWrapper>
      <Panel>
        <p>We're putting together your personalised options</p>
        <Plane />
      </Panel>
    </LoadingWrapper>
  )
}

export default Loading
