import React, { useContext, useEffect } from "react"
import {
  QuestionsDoublePanelContainer,
  QuestionsLeftPanelContent,
  QuestionsRightPanelContent,
  QuestionsPanelWrapper,
  QuestionsPanelTitle,
  TextWrapper,
  OptionsList,
  Option,
  OptionLink,
  BackButton,
  OptionsGrid,
  GridItem,
  OptionCol,
  OptionRow,
  OrModule,
  OrOption,
} from "./style"
import {
  LeftPanelContainer,
  RightPanelContainer,
} from "components/DoublePanel/style"
import "utils/transitions.css"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { FineForm } from "../FineForm"
import FineSelect from "../FineForm/FineSelect"
// import AvenueImg from "components/AvenueImg"
import { ReactComponent as BackArrow } from "images/icons/back-arrow.svg"
import { UserContext } from "../../context/UserContext"
import { FlowContext } from "../../context/FlowContext"

const DoublePanel = ({ className }) => {
  const {
    currentScreen,
    currentScreenIndex,
    handlePrev,
    handleNext,
    firstScreen,
    forwards,
    setCurrentScreen,
    screensData,
    isMobile,
  } = useContext(FlowContext)

  const { storeResponse, setResponses } = useContext(UserContext)

  const screenType = currentScreen.details.type
  const tall = screenType === "grid"
  // console.log("screenType:", screenType)

  useEffect(() => {
    return function cleanUp() {
      setCurrentScreen(screensData[0])
      setResponses([])
    }
  }, [])

  const optionsList = (screenType === "radio" || screenType === "yes/no") && (
    <OptionsList>
      {currentScreen.questionAnswers.map((option, index) => {
        return (
          <li key={index}>
            {option.skipToUrl ? (
              <OptionLink sameTab to={option.skipToUrl}>
                {option.title}
              </OptionLink>
            ) : (
              <Option
                noLine={screenType === "yes/no"}
                onClick={() => {
                  storeResponse(
                    currentScreen.databaseId,
                    currentScreen.title,
                    option.databaseID,
                    option.title
                  )
                  handleNext(option)
                }}
              >
                <span>{option.title}</span>
              </Option>
            )}
            {/* <p>{option.description}</p> */}
          </li>
        )
      })}
    </OptionsList>
  )

  const optionsGrid = screenType === "radiogrid" && (
    <OptionsGrid fullWidth gap={[0]}>
      <OptionRow>
        {currentScreen.questionAnswers.map((option, index) => {
          const showIcon = option.imageUrl !== "false"
          return (
            <OptionCol
              key={index}
              xs={12}
              sm={6}
              onClick={() => {
                storeResponse(
                  currentScreen.databaseId,
                  currentScreen.title,
                  option.databaseID,
                  option.title
                )
                handleNext(option)
              }}
            >
              <GridItem img={showIcon}>
                <h3>{option.title}</h3>
                {/* {option.description && <p>{option.description}</p>} */}
                {showIcon && <img src={option.imageUrl} alt="" />}
              </GridItem>
            </OptionCol>
          )
        })}
      </OptionRow>
    </OptionsGrid>
  )

  const orModule = (screenType === "date" || screenType === "input") && (
    <OrModule>
      {currentScreen.questionAnswers.map((option, index) => {
        return (
          <OrOption key={index}>
            {option.type === "dollar" ? (
              <FineForm
                handleNext={handleNext}
                option={option}
                storeResponse={storeResponse}
                questionId={currentScreen.databaseId}
                questionLabel={currentScreen.title}
                answerId={option.databaseID}
                answerLabel={option.title}
              />
            ) : option.type === "date" ? (
              <FineSelect
                handleNext={handleNext}
                option={option}
                storeResponse={storeResponse}
                questionId={currentScreen.databaseId}
                questionLabel={currentScreen.title}
                answerId={option.databaseID}
                answerLabel={option.title}
              />
            ) : option.type === "radio" ? (
              <Option
                onClick={() => {
                  storeResponse(
                    currentScreen.databaseId,
                    currentScreen.title,
                    option.databaseID,
                    option.title
                  )
                  handleNext(option)
                }}
              >
                <span>{option.title}</span>
              </Option>
            ) : null}
            <span className="or">or</span>
          </OrOption>
        )
      })}
    </OrModule>
  )

  const rightContentCompontent = optionsList || optionsGrid || orModule

  const isDetails = currentScreen.details.step === "step2"
  console.log("isDetails:", isDetails)

  return (
    <QuestionsDoublePanelContainer className={className}>
      <LeftPanelContainer leftColor={isDetails && "#D6D1CC"} nsw tall={tall}>
        <QuestionsPanelWrapper>
          <TransitionGroup
            className="transitionGroup"
            childFactory={child =>
              React.cloneElement(child, {
                classNames: forwards ? "forwards" : "backwards",
              })
            }
          >
            <CSSTransition
              key={currentScreenIndex}
              in={true}
              appear={true}
              timeout={700}
              mountOnEnter={true}
              unmountOnExit={true}
              enter={isMobile ? false : true}
              exit={isMobile ? false : true}
            >
              <QuestionsLeftPanelContent>
                {!firstScreen && (
                  <BackButton onClick={() => handlePrev()}>
                    <BackArrow />
                    Back
                  </BackButton>
                )}
                <QuestionsPanelTitle>
                  <span>{currentScreen.title}</span>
                </QuestionsPanelTitle>
                <TextWrapper
                  dangerouslySetInnerHTML={{
                    __html: currentScreen.details.description,
                  }}
                />
              </QuestionsLeftPanelContent>
            </CSSTransition>
          </TransitionGroup>
        </QuestionsPanelWrapper>
      </LeftPanelContainer>
      <RightPanelContainer nsw rightColor={isDetails && "#EEECEB"}>
        <QuestionsPanelWrapper>
          <TransitionGroup
            className="transitionGroup"
            childFactory={child =>
              React.cloneElement(child, {
                classNames: forwards ? "forwards" : "backwards",
              })
            }
          >
            <CSSTransition
              key={currentScreenIndex}
              in={true}
              appear={true}
              timeout={700}
              mountOnEnter={true}
              unmountOnExit={true}
              enter={isMobile ? false : true}
              exit={isMobile ? false : true}
            >
              <QuestionsRightPanelContent
                tall={tall}
                noPadding={screenType === "radiogrid"}
              >
                {rightContentCompontent}
              </QuestionsRightPanelContent>
            </CSSTransition>
          </TransitionGroup>
        </QuestionsPanelWrapper>
      </RightPanelContainer>
    </QuestionsDoublePanelContainer>
  )
}

export default DoublePanel
