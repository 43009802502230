import React, { useState, useEffect } from "react"
import {
  SelectFormContainer,
  SelectContainer,
  SelectGroup,
  SelectLabel,
  SelectInput,
} from "./style"

const dayOptions = [
  { label: "1", value: "01" },
  { label: "2", value: "02" },
  { label: "3", value: "03" },
  { label: "4", value: "04" },
  { label: "5", value: "05" },
  { label: "6", value: "06" },
  { label: "7", value: "07" },
  { label: "8", value: "08" },
  { label: "9", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
]

const monthOptions = [
  { label: "JAN", value: "JAN" },
  { label: "FEB", value: "FEB" },
  { label: "MAR", value: "MAR" },
  { label: "APR", value: "APR" },
  { label: "MAY", value: "MAY" },
  { label: "JUN", value: "JUN" },
  { label: "JUL", value: "JUL" },
  { label: "AUG", value: "AUG" },
  { label: "SEP", value: "SEP" },
  { label: "OCT", value: "OCT" },
  { label: "NOV", value: "NOV" },
  { label: "DEC", value: "DEC" },
]

const yearOptions = [
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
]

const FineSelect = ({
  storeResponse,
  questionId,
  questionLabel,
  answerLabel,
  answerId,
  option,
  handleNext,
}) => {
  const d = new Date()

  const [day, setDay] = useState(d.getDate())
  const [month, setMonth] = useState(monthOptions[d.getMonth()].label)
  const [year, setYear] = useState(d.getFullYear())

  // var day = d.getDate()
  // var month = d.getMonth() + 1 // Since getMonth() returns month from 0-11 not 1-12
  // var year = d.getFullYear()

  console.log(d.getDate())
  console.log(d.getMonth() + 1)
  console.log(d.getFullYear())

  const handleSubmit = e => {
    e.preventDefault()
    // console.log("day:", day)
    // console.log("month:", month)
    // console.log("year:", year)
    storeResponse(
      questionId,
      questionLabel,
      answerId,
      answerLabel,
      `${day}-${month}-${year}`
    )
    handleNext(option)
  }

  return (
    <SelectFormContainer onSubmit={e => handleSubmit(e)}>
      <SelectContainer>
        <SelectGroup>
          <SelectLabel>Day</SelectLabel>
          <SelectInput value={day} onChange={e => setDay(e.target.value)}>
            {dayOptions.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </SelectInput>
        </SelectGroup>

        <SelectGroup>
          <SelectLabel>Month</SelectLabel>
          <SelectInput value={month} onChange={e => setMonth(e.target.value)}>
            {" "}
            {monthOptions.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </SelectInput>
        </SelectGroup>

        <SelectGroup>
          <SelectLabel>Year</SelectLabel>
          <SelectInput value={year} onChange={e => setYear(e.target.value)}>
            {yearOptions.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </SelectInput>
        </SelectGroup>
      </SelectContainer>
      <button className="submit" type="submit">
        Submit
      </button>
    </SelectFormContainer>
  )
}

export default FineSelect
