import styled from "styled-components"
import { device } from "breakpoints"
import { FlexGrid, FlexCol } from "../FlexGrid"
import { ReactComponent as Airplane } from "../../images/airplane.svg"

import { TopDoublePanel } from "../TopDoublePanel"

import { TopRightPanelContent } from "../TopDoublePanel/style"

export const ResultsTopDoublePanel = styled(TopDoublePanel)`
  ${TopRightPanelContent} {
    svg {
      @media (min-width: 450px) {
        margin-left: 0;
        height: 274px;
      }
    }
  }
`
export const ResultsContainer = styled.div`
  max-width: 100%;
  margin: 0 5px;
`

export const OptionsContainer = styled.div`
  width: 100%;
  max-width: 100%;
  padding-top: 34px;

  &:last-of-type {
    margin-bottom: 40px;
  }

  @media ${device.desktop} {
    padding-top: 114px;
  }
`
export const RowTitle = styled.h2`
  font-size: 26px;
  line-height: 30px;
  color: #111111;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  @media ${device.desktop} {
    font-size: 32px;
    margin-bottom: 40px;
    line-height: 34px;
    justify-content: flex-start;
  }
`
export const OptionTitle = styled.h3`
  font-size: 20px;
  line-height: 30px;
  text-decoration-line: underline;
  color: black;
  display: inline;

  @media ${device.desktop} {
    font-size: 24px;
    line-height: 32px;
  }

  a {
    color: inherit;
  }
`
export const OptionText = styled.p`
  padding-bottom: 53px;

  @media ${device.desktop} {
    font-size: 18px;
    line-height: 28px;
  }
`
export const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #beceda;
  padding: 24px 10px 0 24px;
  background: ${props => (props.important ? "#fdf0af" : "none")};
  border-color: ${props => (props.important ? "#fdf0af" : "#beceda")};
  width: 100%;

  @media ${device.desktop} {
    padding: 32px 28px 0 32px;
  }
`

export const ResultsFlexGrid = styled(FlexGrid)`
  width: calc(100% - 32px) !important;
`
export const ResultsFlexCol = styled(FlexCol)`
  width: 100%;

  @media ${device.desktop} {
  }
`
export const TitleArrowContainer = styled.div`
  display: inline;
  svg {
    vertical-align: middle;
    margin-left: 10px;
    padding-top: 7px;
  }

  @media ${device.desktop} {
    margin-bottom: 25px;
  }
`

// ### Fine Results ###

export const FineResultsContainer = styled.div``
export const FineResultsContainerTitle = styled.h5`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 30px;
  @media ${device.desktop} {
    margin-bottom: 37px;
  }
`
export const FineResultsList = styled.ul`
  list-style-type: none;
  max-width: 360px;
`
export const FineResultsItem = styled.li`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`
export const FineResultsItemName = styled.p`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
  display: block;
  width: 50%;
  @media ${device.desktop} {
    width: 44%;
  }
`
export const FinaResultsItemResponse = styled.p`
  font-size: 18px;
  line-height: 1.5;
  display: block;
  width: 100%;
`

export const LoadingWrapper = styled.div`
  background: #c0dff7;
`

export const Panel = styled.div`
  margin: 0 auto;
  width: calc(100% - 2 * 27px);
  max-width: 1140px;
  height: calc(100vh - 220px);

  p {
    font-size: 20px;
    padding-top: 30px;

    @media ${device.tablet} {
      padding-top: 100px;
      font-size: 28px;
    }
  }
`
export const Plane = styled(Airplane)`
  width: 150px;
  height: auto;

  @media ${device.tablet} {
    float: right;
    width: 400px;
    height: 297px;
  }
`
